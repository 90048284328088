<script setup lang="ts">
    import {useI18n} from 'vue-i18n';
    import {computed} from 'vue';
    import useCpBem from '~/composables/useCpBem';
    import CpText from '~/components/cp/CpText/CpText.vue';
    import CpProductImage from '~/components/cp/CpProductImage/CpProductImage.vue';
    import CpPillCard from '~/components/cp/CpPill/CpPillCard/CpPillCard.vue';
    import CpProductRate from '~/components/cp/CpProductRate/CpProductRate.vue';
    import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';
    import CpTextParser from '~/components/cp/CpTextParser/CpTextParser.vue';
    import {getEtaMessage} from '~/utils/etaUtils';
    import type {IEtaInfo} from '~/types';

    const props = defineProps<{
      kind: 'FeaturedOffer'|'Standard'|'Offer'|'Featured'|'Oos'|'OosOffer',
      productType: 'physical'|'esd',
      pillLarge: boolean,
      pillShort: boolean,
      rowLarge?: boolean,
      rowShort?: boolean,
      rate: boolean,
      rateValue: number,
      reviews: number,
      freeShipping: boolean,
      link: string,
      title: string,
      hasDiscount: boolean,
      discount: string | boolean,
      picture: string,
      imageTitle: string,
      price: number,
      tPrice: number,
      id: string,
      eta?: boolean,
      /** indicate that the card is not used in home page or catalog  */
      standard?: boolean,
      etaData?: IEtaInfo | { messages: IEtaInfo['messages'] }
    }>();

    const {b, e, em} = useCpBem('cp-product-card');
    const {t} = useI18n();

    // props.productType === 'physical' ? 'physical' : 'esd'
    const displayMessage = computed(() => getEtaMessage(props.etaData));
    const priceFormat = props.price.toLocaleString('es-MX', {style: 'currency', currency: 'MXN'});
    const tPriceFormat = props.tPrice.toLocaleString('es-MX', {style: 'currency', currency: 'MXN'});
</script>
<template>
    <CpNuxtLink :class="b" data-testid="product-card" page-name="details" :link="link">
        <!--Pills-->
        <div v-if="kind || productType === 'esd'" :class="e('pills')">
            <CpPillCard
                v-if="kind === 'Featured'"
                variant="featuredProduct"
                kind="standard"
                :class="e('first')"
            />
            <CpPillCard
                v-if="discount && kind === 'FeaturedOffer'"
                variant="featuredProduct"
                kind="discount"
                :discount="discount"
                :class="e('first')"
            />
            <CpPillCard
                v-if="discount && kind === 'Offer'"
                variant="offer"
                :discount="discount"
                :class="e('first')"
            />
            <CpPillCard
                v-if="kind === 'Oos' || kind === 'OosOffer'"
                variant="oosEol"
                :class="e('first')"
            />
            <CpPillCard
                v-if="productType === 'esd' && pillLarge"
                variant="esd"
                :class="e('first')"
            />
            <CpPillCard
                v-if="productType === 'esd' && pillShort"
                variant="esd"
                short
                :class="kind !== 'Standard' ? e('esd-pill') : ''"
            />
            <CpPillCard
                v-if="productType === 'physical' && rowLarge"
                variant="rowProduct"
                :class="e('first')"
            />
            <CpPillCard
                v-if="productType === 'physical' && rowShort"
                variant="rowProduct"
                short
                :class="kind !== 'Standard' ? e('esd-pill') : ''"
            />
        </div>
        <!--Image-->
        <div :class="em('image-container', {standard: standard})">
            <CpProductImage
                :class="e('image')"
                :picture="picture"
                :title="imageTitle"
                :height="standard ? 120 : 140"
                :width="standard ? 120 : 140"
            />
        </div>
        <!--Product Info-->
        <div :class="em('info-container', {'eta': eta})" data-testid="product-card-info">
            <CpProductRate
                v-if="rate"
                :rate="rateValue"
                :total-reviews="reviews"
                :hide-count="reviews < 1"
            />
            <div class="pt-1">
                <CpText variant="body-regular" type="primary" :truncate-lines="3" data-testid="product-card-title">
                    {{ title }}
                </CpText>
            </div>
            <div v-if="eta && productType === 'physical' && displayMessage" :class="e('eta')">
                <CpTextParser :text="displayMessage" variant="caption" />
            </div>
            <div v-if="eta && productType === 'esd'" :class="e('eta')">
                <CpText variant="caption-bold">
                    {{ t('immediateDelivery') }}
                </CpText>
                <CpText variant="caption-regular">
                    {{ t('processingOrder') }}
                </CpText>
            </div>
            <CpText
                v-if="freeShipping && productType !== 'esd'"
                variant="caption-bold"
                type="mkt-dark"
                :class="e('shipping')"
            >
                {{ t('freeShipping') }}
            </CpText>
        </div>
        <!--Price-->
        <div :class="e('price-container')">
            <CpText v-if="hasDiscount" :class="e('discount-price')" variant="price-from" type="neutral">
                {{ tPriceFormat }}
            </CpText>
            <CpText
                :variant="hasDiscount ? 'price-offer' :'price-regular'"
                :type="hasDiscount ? 'mkt-dark' : 'neutral-dark'"
                data-testid="product-price"
            >
                {{ priceFormat }}
            </CpText>
        </div>
    </CpNuxtLink>
</template>

<style scoped lang="scss">
    .cp-product-card {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px;
        text-decoration: none;
        &__row {
          display: flex;
        }
        &__column {
          display: flex;
          flex-direction: column;
        }
        &__first {
          z-index: 2;
        }
        &__pills {
          margin-top: -9px;
          margin-left: -9px;
          display: flex;
          position: absolute;
        }
        &__text {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__shipping {
          padding-top: 1px;
        }
        &__image-container {
          height: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
          &--standard {
            height: 120px;
            max-width: 120px;
            align-self: center;
          }
        }
        &__info-container {
          height: 88px;
          display: flex;
          flex-direction: column;
          &--eta {
            height: 116px;
          }
        }
        &__eta {
          line-height: 12px;
          margin-top: 4px;
        }
        &__price-container {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
        }
        &__discount-price {
          text-decoration: line-through;
        }
        &__esd-pill {
          margin-left: -6px;
          z-index: 1;
        }
}
</style>

<i18n locale="es-US">
    freeShipping: "+Envío gratis"
    receiveIt: "Recibelo entre el"
    immediateDelivery: "Entrega inmediata"
    processingOrder: "al procesar tu pedido"
</i18n>
